import { useEffect } from 'react';
import Footer from '../components/Footer';
import { scroller } from 'react-scroll';

export default function Finished() {
  useEffect(() => {
    scroller.scrollTo("startDiv", {});
  }, []);

  window.onbeforeunload = null;

  return (
    <div id="startDiv" className="bg-white">
      <div className="">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:flex lg:justify-between">
          <div className="max-w-xl">
            <h2 className="text-4xl font-extrabold text-zinc-700 sm:text-5xl sm:tracking-tight lg:text-6xl z-1">
              Thank you for participating!
            </h2>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
