import React, { createContext, useContext, useState, ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';

interface LanguageContextType {
    language: string;
    changeLanguage: (lang: string) => void;
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

interface LanguageProviderProps {
    children: ReactNode;
}

const SUPPORTED_LANGUAGE_CODES = ["en", "es"];

export const LanguageProvider: React.FC<LanguageProviderProps> = ({ children }) => {
    const [searchParams] = useSearchParams();
    const queryLanguage = searchParams.get("lang");
    let defaultLanguage = "en"
    if(queryLanguage && SUPPORTED_LANGUAGE_CODES.includes(queryLanguage)) {
        defaultLanguage = queryLanguage
    }

    const [language, setLanguage] = useState<string>(defaultLanguage);

    const changeLanguage = (lang: string) => {
        if(SUPPORTED_LANGUAGE_CODES.includes(lang)) {
            setLanguage(lang);
        }
    };

    return (
        <LanguageContext.Provider value={{ language, changeLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = (): LanguageContextType => {
    const context = useContext(LanguageContext);
    if (!context) {
        throw new Error('useLanguage must be used within a LanguageProvider');
    }
    return context;
};

export const useContent = () => {
    const { language } = useLanguage();
    function getContent(key: string) {
        return content[key][language];
    }   
    return getContent;
}


const content: any = {
    title: {
        en: "Group Well-Being Assesment",
        es: "Evaluación del bienestar grupal"
    },
    enterKey: {
        en: "Enter assessment key",
        es: "Ingrese la clave de evaluación"
    },
    enter: {
        en: "Enter",
        es: "Ingresar"
    },
    acceptDisclaimer: {
        en: "I acknowledge having read this disclaimer and agree to Strata's use of my responses as described in this disclaimer.",
        es: "Reconozco haber leído este descargo de responsabilidad y acepto el uso de mis respuestas por parte de Strata según lo descrito en este descargo de responsabilidad"
    },
    continue: {
        en: "Continue",
        es: "Continuar"
    },
    yearsExperience: {
        en: "How many years have you been in your current role?",
        es: "¿Cuántos años lleva en su puesto actual?"
    },
    age: {
        en: "What is your age?",
        es: "¿Cuál es su edad?"
    },
    gender: {
        en: "What is your gender?",
        es: "¿Cuál es su género?"
    },
    navigateAway: {
        en: "Are you sure you want to navigate away? Your responses will not be saved.",
        es: "¿Está seguro de que quiere salir de esta página? Sus respuestas no se guardarán."
    },
    question: {
        en: "Question",
        es: "Pregunta"
    },
    of: {
        en: "of",
        es: "de"
    },
    finish: {
        en: "Finish",
        es: "Finalizar"
    }
}