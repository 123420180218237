import React, { useState, useContext, useEffect } from "react";
import ResponseType from "../components/ResponseType";
import Button from "../components/Button";
import { Question, OptionChoice, Response } from "../types";
import SurveyContext from "../context/surveyContext";
import SideDecorations from "../components/SideDecorations";
import { useNavigate, useParams } from "react-router-dom";
import { scroller } from "react-scroll";
import Spinner from "../components/Spinner";
import Timer from "../components/Timer";
import { useContent, useLanguage } from "../context/languageContext";

const SurveyBody: React.FC = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const { contextState, contextDispatch } = useContext(SurveyContext);
  const { language } = useLanguage();
  const content = useContent();

  const [optionChoices, setOptionChoices] = useState<OptionChoice[]>([]);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [responses, setResponses] = useState<Response[]>([]);

  const navigationPage = parseInt(params.currentPage as string);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState<number>(navigationPage);
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);

  async function getQuestions() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/survey/questions`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Content-Language": language,
          Authorization: contextState.user.survey_key ?? "",
        },
      });
      const body = await response.json();
      setQuestions(body);
    } catch (err) {
      console.error(err);
    }
  }

  async function getOptionChoices() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/survey/questions/option-choices`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Content-Language": language,
          Authorization: contextState.user.survey_key ?? "",
        },
      });
      const body = await response.json();
      setOptionChoices(body);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    getQuestions();
    getOptionChoices();
  }, [language]);

  function scrollTo(id: string) {
    scroller.scrollTo(id, {
      duration: 400,
      delay: 0,
      smooth: true,
      offset: -100,
    });
  }

  // gets an array of unique page numbers from all the questions
  const pages = [...Array.from(new Set(questions.map((question) => question.page_number)))];

  useEffect(() => {
    window.onpopstate = null;
    setCurrentPage(navigationPage);

    // if there is no survey information (proabably due to a refresh), send them to the home page
    if (!contextState.surveyId) {
      navigate("/");
    }
  }, [navigationPage]);

  const currentQuestions = questions.filter((q) => q.page_number === currentPage);
  const pastQuestions = questions.filter((q) => q.page_number < currentPage);

  async function nextOrSubmit() {
    if (responses.length < pastQuestions.length + currentQuestions.length) {
      setError("Please answer all the questions");
      const firstUnansweredQuestionIndex = questions.findIndex(q => !responses.find(r => r.question_id === q.question_id));
      scrollTo(`question-${pastQuestions.length + firstUnansweredQuestionIndex}`);
      setCurrentQuestionIndex(pastQuestions.length + firstUnansweredQuestionIndex);
    }
    // if not the last section, go to the next section
    else if (currentPage < pages.length) {
      navigate(`/survey/${currentPage + 1}`);
      setError("");
      setCurrentPage(currentPage + 1);
      setCurrentQuestionIndex(pastQuestions.length + currentQuestions.length);
      scrollTo("question-0");
    } else {
      setSubmitting(true);
      try {
        // post responses to server
        const response = await fetch(`${process.env.REACT_APP_API_URL}/survey/responses`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: contextState.user.survey_key ?? "",
          },
          body: JSON.stringify({
            responses,
            user: contextState.user,
            startTime: contextState.startTime
          }),
        });

        if (response.status === 200) {
          contextDispatch({ type: "clearContext", payload: {} });
          window.onbeforeunload = null;
          navigate("/finished");
        } else {
          //error handling
          console.error(response);
          let body = await response.json();
          console.error(body);
          alert(body.errors[0]);
        }
      } catch (err) {
        console.error(err);
      }
      setSubmitting(false);
    }
  }

  // if the optionChoices haven't been loaded yet, display the loading spinner
  if (questions.length <= 0 || optionChoices.length <= 0) {
    return <Spinner />;
  } else
    return (
      <div className="relative py-20 bg-white overflow-hidden">
        <SideDecorations />
        {currentQuestions.map((question: Question, index: number) => (
          <div
            id={`question-${pastQuestions.length + index}`}
            key={index}
            className={`relative px-4 sm:px-6 lg:px-8 ${pastQuestions.length + index === currentQuestionIndex ? "opactiy-100" : "opacity-25"}`}
          >
            <div className="mx-auto">
              <div>
                <h1>
                  <span className="block text-base text-center text-strata-blue font-semibold tracking-wide uppercase">
                    {content("question")} {pastQuestions.length + index + 1} {content("of")} {questions.length}
                  </span>
                </h1>
              </div>
              <div className="mb-20">
                <ResponseType
                  optionChoices={optionChoices.filter((oc) => oc.option_group_id === question.option_group_id)}
                  question={question}
                  selectedOptionId={responses.find((r) => r.question_id === question.question_id)?.option_choice_id}
                  setSelectedOption={(optionChoiceId: number) => {
                    // update responses
                    const responseIndex = responses.findIndex((response: Response) => response.question_id === question.question_id);
                    const response = {
                      question_id: question.question_id,
                      option_choice_id: optionChoiceId
                    }
                    const newResponses = [...responses];
                    if (responseIndex === -1) {
                      newResponses.push(response);
                    } else {
                      newResponses[responseIndex] = response;
                    }
                    setResponses(newResponses);

                    index === currentQuestions.length - 1 ? scrollTo("bottom") : scrollTo(`question-${pastQuestions.length + index + 1}`);
                    setCurrentQuestionIndex(pastQuestions.length + index + 1);
                  }}
                />
              </div>
            </div>
          </div>
        ))}

        <div id="bottom" className="relative px-4 sm:px-6 lg:px-8 text-center mb-1">
          <div className="text-red-500 mb-5">{error}</div>
          <Button onClick={nextOrSubmit} text={currentPage < pages.length ? content("continue") : content("finish")} size="lg" submitting={submitting} />
        </div>
        <Timer />
      </div>
    );
};

export default SurveyBody;
