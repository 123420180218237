import { useState, useEffect, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { OptionChoice } from "../../types";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function Dropdown(props: {
  dropDownItems: Array<OptionChoice>;
  selectedOptionId: number;
  setSelectedOption: Function;
}) {
  const [selected, setSelected] = useState("Select one...");

  useEffect(() => {
    if (
      props.dropDownItems.find(
        (option) => option.option_choice_id === props.selectedOptionId
      )
    ) {
      setSelected(
        props.dropDownItems.find(
          (option) => option.option_choice_id === props.selectedOptionId
        )!.option_choice_name
      );
    }
  }, [props.dropDownItems]);

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div className="">
        <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-zinc-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-sky-500">
          {selected}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-40 origin-top-right absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {props.dropDownItems.map(
              (optionChoice: { option_choice_name: string }, index: number) => (
                <Menu.Item key={index}>
                  {({ active }) => (
                    <a
                      className={classNames(
                        active ? "bg-gray-100 text-zinc-900" : "text-zinc-700",
                        "block px-4 py-2 text-sm"
                      )}
                      onClick={() => {
                        setSelected(optionChoice.option_choice_name);
                        props.setSelectedOption(optionChoice);
                      }}
                    >
                      {optionChoice.option_choice_name}
                    </a>
                  )}
                </Menu.Item>
              )
            )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
