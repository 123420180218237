import React, { useReducer } from "react";
import { Route, BrowserRouter as Router, Routes, Navigate } from "react-router-dom";

import Home from "./pages/Home";
import Survey from "./pages/Survey";
import Finished from "./pages/Finished";
import { SurveyContextProvider, surveyContextReducer, initalSurveyContextState } from "./context/surveyContext";
import Demographic from "./pages/Demographic";
import Timer from "./components/Timer";
import Disclaimer from "./pages/Disclaimer";
import { LanguageProvider } from "./context/languageContext";
import LanguageSelector from "./components/LanguageSelector";

export interface IContextProps {}

const App: React.FC<IContextProps> = (props) => {
  //@ts-ignore
  const [surveyContextState, surveyContextDispatch] = useReducer(surveyContextReducer, initalSurveyContextState);

  const SurveyContextValues = {
    contextState: surveyContextState,
    contextDispatch: surveyContextDispatch,
  };

  return (
    <Router>
      <SurveyContextProvider value={SurveyContextValues}>
        <LanguageProvider>
          {/* <LanguageSelector /> */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/*" element={<Navigate to={"/"} />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="/start" element={<Demographic />} />
            <Route path="/survey/:currentPage" element={<Survey />} />
            <Route path="/finished" element={<Finished />} />
            <Route path="/timer" element={<Timer />} />
          </Routes>
        </LanguageProvider>
      </SurveyContextProvider>
    </Router>
  );
};

export default App;
