import React from "react";
import { createContext } from "react";
import { Response, User } from "../types";

export interface IContextActions {
  type: "updateUser" | "updateSurveyId" | "setStartTime" | "setExpireTime" | "clearContext";
  payload: Response | User | Date | {};
}

export interface IContextState {
  user: User;
  surveyId: number | null;
  startTime: Date;
  expireTime: Date;
}

export const initalSurveyContextState: IContextState = {
  user: {
    survey_key: null,
    age_id: null,
    years_experience_id: null,
    gender_id: null,
  },
  surveyId: null,
  startTime: new Date(),
  expireTime: new Date(0),
};

export const surveyContextReducer = (state: IContextState, action: IContextActions) => {
  switch (action.type) {
    case "updateUser":
      Object.assign(state.user, action.payload);
      return state;
    case "updateSurveyId":
      return {
        ...state,
        surveyId: action.payload,
      };
    case "setStartTime":
      if (action.payload instanceof Date) {
        state.startTime = action.payload;
      }
      return state;
    case "setExpireTime":
      if (action.payload instanceof Date) {
        state.expireTime = action.payload;
      }
      return state;
    case "clearContext":
      state = {
        user: {
          survey_key: null,
          age_id: null,
          years_experience_id: null,
          gender_id: null,
        },
        surveyId: null,
        startTime: new Date(),
        expireTime: new Date(0),
      };
      return state;
    default:
      return state;
  }
};

export interface IContextProps {
  contextState: IContextState;
  contextDispatch: React.Dispatch<IContextActions>;
}

const SurveyContext = createContext<IContextProps>({
  contextState: initalSurveyContextState,
  contextDispatch: () => {},
});

export const SurveyContextConsumer = SurveyContext.Consumer;
export const SurveyContextProvider = SurveyContext.Provider;
export default SurveyContext;
