import { OptionChoice } from "../../types";

export default function Radio(props: {
  title: string;
  selectItems: Array<OptionChoice>;
  selectedOptionId: number;
  setSelectedOption: Function;
}) {
  return (
    <div className="">
      <fieldset className="mt-4">
        <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
          {props.selectItems.map(
            (optionChoice: OptionChoice, index: number) => (
              <div key={index} className="flex items-center">
                <input
                  id={optionChoice.option_choice_name}
                  name={props.title}
                  type="radio"
                  defaultChecked={
                    optionChoice.option_choice_id === props.selectedOptionId
                  }
                  className="ml-1 h-4 w-4 text-strata-blue border-gray-300"
                  onClick={() => props.setSelectedOption(optionChoice)}
                />
                <label
                  htmlFor={optionChoice.option_choice_name}
                  className="ml-3 block text-sm font-medium text-zinc-700"
                >
                  {optionChoice.option_choice_name}
                </label>
              </div>
            )
          )}
        </div>
      </fieldset>
    </div>
  );
}
