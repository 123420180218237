
export default function Button(props: {
  text: string;
  size?: "sm" | "md" | "lg";
  type?: "button" | "submit" | "reset" | undefined;
  submitting?: boolean;
  onClick?: () => void;
}) {
  return (
    <>
      <button
        disabled={props.submitting}
        type={props.type ? props.type : "button"}
        className={`items-center ${
          props.size === "sm"
            ? "px-2 py-1 text-sm"
            : props.size === "lg"
            ? "px-7 py-3 text-lg"
            : "px-4 py-2 text-sm"
        } mt-4 px-12 py-3 border border-transparent text-base font-medium rounded-sm text-white bg-strata-blue shadow-sm hover:bg-zinc-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-strata-blue lg:mt-0 sm:flex-shrink-0 w-full sm:w-auto`}
        onClick={props.onClick}
      >
        {props.submitting ? (
            <div className={`animate-spin rounded-full mx-auto h-7 w-7 border-b-2 border-white`}></div>
        ) : (
          props.text
        )}
      </button>
    </>
  );
}
