import { useState, useEffect } from "react";
import { Range, getTrackBackground } from "react-range";
import { OptionChoice } from "../../types";

function Slider(props: {
  optionChoices: OptionChoice[];
  selectedOptionId: number | undefined;
  setSelectedOption: Function;
}) {
  const [value, setValue] = useState<number[]>([0]);

  const MIN = parseInt(props.optionChoices[0].option_choice_name);
  const MAX = parseInt(
    props.optionChoices[props.optionChoices.length - 1].option_choice_name
  );

  const STEP = props.optionChoices[0]
    ? parseFloat(props.optionChoices[1].option_choice_name) -
      parseFloat(props.optionChoices[0].option_choice_name)
    : 1;

  const selectedOptionChoice = props.optionChoices.find((optionChoice) => {
    return optionChoice.option_choice_name === value[0].toString();
  });

  const selectedValue = props.optionChoices.find((optionChoice) => {
    return optionChoice.option_choice_id === props.selectedOptionId;
  })?.option_choice_name;

  useEffect(() => {
    if (selectedValue) {
      setValue([parseFloat(selectedValue)]);
    }
  }, [selectedValue]);

  return (
    <div
      className="flex justify-center flex-wrap mt-4"
    >
      <Range
        values={value}
        step={STEP}
        min={MIN}
        max={MAX}
        onChange={(values) => setValue(values)}
        onFinalChange={(values) =>
          props.setSelectedOption(selectedOptionChoice)
        }
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "36px",
              display: "flex",
              width: "100%",
              margin: "15px",
            }}
          >
            <div className="absolute -top-5">{value[0] > MIN ? MIN : ""}</div>
            <div
              ref={props.ref}
              style={{
                height: "5px",
                width: "100%",
                borderRadius: "4px",
                background: getTrackBackground({
                  values: value,
                  colors: ["#548BF4", "#ccc"],
                  min: MIN,
                  max: MAX,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
            <div className="absolute right-0 -top-5">
              {value[0] < MAX ? MAX : ""}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "42px",
              width: "42px",
              borderRadius: "4px",
              backgroundColor: "#FFF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 2px 6px #AAA",
            }}
          >
            <output
              className="mb-24 absolute text-xl font-semibold"
              id="output"
            >
              {value[0]}
            </output>
            <div
              style={{
                height: "16px",
                width: "5px",
                backgroundColor: isDragged ? "#548BF4" : "#CCC",
              }}
            />
          </div>
        )}
      />
    </div>
  );
}
export default Slider;
