import { useEffect, useState } from "react";
import { useContext } from "react";
import SurveyContext from "../context/surveyContext";
import Icon from "../resources/main-icon.svg";
import LogoWords from "../resources/main-no-icon.svg";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import { useContent } from "../context/languageContext";

export default function Home() {
  const [error, setError] = useState(false);
  const [agreed, setAgreed] = useState(false);

  const navigate = useNavigate();
  const content = useContent();
  const { contextState, contextDispatch } = useContext(SurveyContext);

  // Add Reload Site? confirmation dialog
  window.onbeforeunload = () => {
    return "";
  };

  useEffect(() => {
    if (!contextState.surveyId) {
      navigate("/");
    }
  }, []);

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-4 px-2 sm:py-8 lg:px-8">
        <div className="text-center h-screen">
          <img src={Icon} alt="Icon" className="h-[4%] mx-auto" />
          <img src={LogoWords} alt="logo" className="h-[3%] mx-auto mt-2" />
          <h1 className="text-center text-lg text-strata-blue font-bold mt-1">{content("title")}</h1>
          <div id="disclaimer" className="border-2 h-1/2 overflow-auto mt-2">
            <h2 className="text-md font-semibold text-gray-600 mt-3">User Disclaimer</h2>
            <div className="mx-auto mt-2 max-w-3xl text-sm px-1 text-gray-500">
              <p className="mb-5">
                The Group Well-Being Assessment is a tool developed by Strata Intel Inc. (“Strata”) to identify organizational well-being. Your participation in this
                assessment is purely voluntary, and it is important that your organization does not require or reward your participation. For your responses to be
                registered, however, you will need to respond to each question because the program does not allow any question to be skipped.
              </p>
              <p className="mb-5">
                Your answers will be combined with answers from other individuals in your organization to create a report for presentation to management. Such report will
                show data based solely on aggregated responses. To further preserve your anonymity, the assessment collects almost no personally identifiable information
                to ensure that neither your organization nor Strata can trace any responses back to you. The only information collected through this assessment that is
                directly tied to you individually is whether you complete the assessment. Strata uses this information to determine whether to send you reminders and to
                report the assessment's response rate, but it does not report to the organization whether you, individually, responded to the assessment. Your responses
                will also be organized by work group (i.e., clinical professional, clinical support, non-clinical, or executive/management), but in the event your work
                group contains less than six invited participants, your responses will be automatically discarded as a further safeguard to prevent your organization or
                Strata from being able to identify any response as having come from you.
              </p>
              <p className="mb-5">
                In addition to the questions used to determine organizational well-being, the assessment will ask three demographic questions. These questions
                specifically ask: (i) the age range you fall within; (ii) your gender; and (iii) the time range in which you have served in your current work role.
                Neither your organization nor Strata can trace answers to these questions back to you. Further, the report delivered to your organization will not contain
                participants' responses to these demographic questions or sort responses to the organizational well-being questions by age, gender, or work longevity.
                Strata, however, will use your responses to the demographic questions to conduct further research, and depending upon the outcomes of such research,
                Strata may in the future improve the Group Well-Being Assessment so that certain questions vary depending upon a participant's age, gender, and/or service
                longevity.
              </p>
              <p className="mb-5">
                This assessment does not serve as a clinical, diagnostic, or medical assessment, and consequently, it cannot and does not diagnose any medical condition.
                If you feel you are in need of any type of healthcare (including mental healthcare), please contact a licensed healthcare professional for evaluation. As
                discussed above, this assessment does not collect personally identifiable information, so neither your organization nor Strata will be able to assist you
                with any health or workplace condition as a result of responses you provide.
              </p>
              <p className="mb-5">
                Subject to Strata maintaining the anonymity of your responses as described above, you agree by checking the box below that: (i) the responses you provide
                in the assessment become the property of Strata; and (ii) Strata may use your responses to provide well-being reports to your organization, to improve the
                Group Well-Being Assessment, to incorporate in research papers, and to develop additional products.
              </p>
            </div>
          </div>
          <div className="h-[20%]">
            <div className="max-w-3xl mx-auto flex items-start mt-3">
              <div className="flex">
                <input
                  id="agree"
                  name="agree"
                  type="checkbox"
                  value={"true"}
                  className="w-7 h-7 rounded border-gray-300 text-strata-blue focus:ring-strata-blue"
                  onChange={(e) => setAgreed(e.target.checked)}
                />
              </div>
              <div className="mx-1 ml-3 text-left mb-2">
                <label htmlFor="agree" className="text-gray-600">
                  {content("acceptDisclaimer")}
                </label>
              </div>
            </div>
            {error && !agreed && <p className="text-sm text-red-500 mb-4">You must accept the terms and conditions to continue</p>}
            <Button
              text={content("continue")}
              type="submit"
              size="lg"
              onClick={() => {
                if (agreed) {
                  contextDispatch({
                    type: "setExpireTime",
                    payload: new Date(new Date().setMinutes(new Date().getMinutes() + 20)),
                  });
                  contextDispatch({
                    type: "setStartTime",
                    payload: new Date(),
                  });
                  navigate("/start");
                } else {
                  setError(true);
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
