import { useState, useEffect, useContext } from "react";
import SurveyContext from "../context/surveyContext";

const Timer = (props: any) => {
  const { contextState } = useContext(SurveyContext);

  const expireTime = contextState.expireTime;
  const currentTime = new Date();

  const [minutes, setMinutes] = useState<number>();
  const [seconds, setSeconds] = useState<number>();

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (expireTime.getTime() - currentTime.getTime() < 0) {
        setMinutes(0);
        setSeconds(0);
        clearInterval(myInterval);
        alert("The time for this survey has expired");
        window.onbeforeunload = null;
        window.location.reload();
      } else {
        setMinutes(new Date(expireTime.getTime() - currentTime.getTime()).getMinutes());
        setSeconds(new Date(expireTime.getTime() - currentTime.getTime()).getSeconds());
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  if (!minutes || !seconds) {
    return (
      <div className="fixed bottom-10 right-10 bg-sky-400 rounded-3xl text-xl p-2 text-white w-20 text-center hidden">
        <h1>--:--</h1>
      </div>
    );
  }
  return (
    <div className="fixed bottom-10 right-10 bg-sky-400 rounded-3xl text-xl p-2 text-white w-20 text-center hidden">
      <h1>
        {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
      </h1>
    </div>
  );
};

export default Timer;
