import Icon from "../resources/main-icon.svg";
import LogoWords from "../resources/main-no-icon.svg";

export default function Footer() {
  return (
    <footer className="bg-white" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1 relative ">
            <div className="flex absolute bottom-0">
              <img src={Icon} alt="Icon" className="h-16" />
              <img src={LogoWords} alt="logo" className="h-12 mt-4 ml-2" />
            </div>
          </div>
        </div>
        <div className="mt-12 border-t border-gray-200 pt-8">
          <p className="text-base text-zinc-400 xl:text-center">
            &copy; {new Date().getFullYear()} Strata Intel, Inc. All rights
            reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
